"use client"
import { usePathname } from "next/navigation";
import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

type initialContextType = {
    withLocalNav: boolean
}
const initialContext: initialContextType = {
    withLocalNav: false,
}

const HistoryContext = createContext(initialContext)

const UrlHistoryProvider = ({ children }: { children: ReactNode }) => {
    const pathname = usePathname()

    const ref = useRef<boolean>(true)
    const [isNavigated, setNavigated] = useState(false)

    useEffect(() => {
        if (!!ref.current) {
            ref.current = false
            return
        }
        setNavigated(true)
        return () => setNavigated(false)
    }, [pathname])

    return (
        <HistoryContext.Provider value={{ withLocalNav: isNavigated }}>
            {children}
        </HistoryContext.Provider>
    )
}

export const useHistory = () => useContext(HistoryContext)

export default UrlHistoryProvider
