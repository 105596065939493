"use client";
import { UserSession } from "@/types/user-session";
import SetToken from "./SetToken";

type Props = {
  userSession?: UserSession;
};

export default function ClientGlobally({ userSession = null }: Props) {
  const userToken = userSession?.data?.accessToken || undefined;

  return (
    <>
      <SetToken token={userToken} />
    </>
  );
}
