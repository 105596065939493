import axiosInstance from "@/api/axios";
import React, { useEffect } from "react";

type Props = {
  token?: string;
};

export default function SetToken({ token = null }: Props) {
  useEffect(() => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }, [token]);
  return null;
}
